export const getEvents = `${process.env.REACT_APP_API_URL}/event/events`;
export const login = `${process.env.REACT_APP_API_URL}/organizer/login`;
export const register = `${process.env.REACT_APP_API_URL}/organizer/create-organizer`;
export const subscribe = `${process.env.REACT_APP_API_URL}/email/subscribe-newsletter`;
export const getAllBanks = `${process.env.REACT_APP_API_URL}/account/get-all-banks`;
export const changePassword = `${process.env.REACT_APP_API_URL}/organizer/reset-password`;
export const getPaymentInfo = `${process.env.REACT_APP_API_URL}/organizer/payment-info/get`;
export const getOrganizer = `${process.env.REACT_APP_API_URL}/organizer/profile`;
export const savePaymentInfo = `${process.env.REACT_APP_API_URL}/organizer/payment-info/update`;
export const resolveAccount = `${process.env.REACT_APP_API_URL}/account/verify/account-number`;
export const profileUpdate = `${process.env.REACT_APP_API_URL}/organizer/profile/update`;
export const contactUpdate = `${process.env.REACT_APP_API_URL}/organizer/update`;