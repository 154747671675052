import React, { useState, useEffect } from 'react';
import './AccountInfo.css';
import profilePlaceholder from '../../assets/profile-placeholder.png'; // Replace with actual path to profile placeholder image
import PaymentInfo from './PaymentInfo';
import { getOrganizer, profileUpdate, contactUpdate } from '../../constants/routes';
// import { getToken } from '../../utils/auth';
import { toast } from 'react-toastify';

const AccountInfo = ({ allBanks }) => {

  const bankOptions = allBanks.map(bank => ({
    value: bank.name,
    label: bank.name,
    code: bank.code,
    id: bank.id
  }));

  const [contactInfo, setContactInfo] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setHomeAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [websiteLink, setWebsite] = useState('');
  const [facebookLink, setFacebook] = useState('');
  const [twitterLink, setTwitter] = useState('');
  const [about, setAbout] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingContact, setLoadingContact] = useState(false);
  // const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    // const token = getToken();
    const fetchProfile = async () => {
      try {
        const response = await fetch(getOrganizer, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
          credentials: 'include',

        });
        if (!response.ok) {
          throw new Error(`This is an HTTP error: The status is ${response.status}`);
        }
        const data = await response.json();
        const organizer = data.organizer;
        setContactInfo(organizer.contactInfo);
        setFirstName(organizer.contactInfo.firstName || '');
        setLastName(organizer.contactInfo.lastName || '');
        setHomeAddress(organizer.contactInfo.homeAddress || '');
        setPhoneNumber(organizer.contactInfo.phoneNumber || '');
        setWebsite(organizer.websiteLink || '');
        setFacebook(organizer.facebookLink || '');
        setTwitter(organizer.twitterLink || '');
        setAbout(organizer.about || '');
        setProfileImage(organizer.contactInfo.profileImage);
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };

    fetchProfile();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      resizeImage(file, 200, 200, (resizedImage) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result.replace(/^data:image\/[a-z]+;base64,/, '');
          // setSelectedImage(base64data);
          handleImageUpload(base64data);
        };
        reader.readAsDataURL(resizedImage);
      });
    }
  };

  const resizeImage = (file, maxWidth, maxHeight, callback) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = maxWidth;
        canvas.height = maxHeight;
        ctx.drawImage(img, 0, 0, maxWidth, maxHeight);
        canvas.toBlob(callback, 'image/jpeg');
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleImageUpload = async (blob) => {

    const payload = {
      'profileImage': blob
    };

    try {
      const response = await fetch(profileUpdate, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(payload)
      });
      if (!response.ok) {
        throw new Error('Failed to upload image');
      }
      const data = await response.json();
      setProfileImage(data.contactInfo.profileImage);
    } catch (error) {
      console.error('Upload error:', error);
    }
  };

  const validateProfile = () => {
    const errors = {};
    if (!firstName) errors.firstName = 'First name is required';
    if (!lastName) errors.lastName = 'Last name is required';
    if (!address) errors.address = 'Address is required';
    if (!phoneNumber) errors.phoneNumber = 'Phone number is required';
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateContact = () => {
    const errors = {};
    if (!websiteLink) errors.websiteLink = 'Website link is required';
    if (!facebookLink) errors.facebookLink = 'Facebook link is required';
    if (!twitterLink) errors.twitterLink = 'Twitter link is required';
    if (!about) errors.about = 'Twitter link is required';
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!validateProfile()) return;

    setLoadingProfile(true);
    const headers = {
      'Content-Type': 'application/json'
    };

    const payload = {
      firstName: firstName,
      lastName: lastName,
      homeAddress: address,
      phoneNumber: phoneNumber
    };

    try {
      const response = await fetch(
        profileUpdate,
        {
          headers,
          method: 'PATCH',
          body: JSON.stringify(payload),
          credentials: 'include'
        }
      );

      if (!response.ok) {
        throw new Error(`This is an HTTP error: The status is ${response.status}`);
      }
      const data = await response.json();
      if (data.status) {
        toast.success(`${data.message}`);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(`Failed to update profile: ${error.message}`);
    } finally {
      setLoadingProfile(false);
    }
  }

  const handleContactUpdate = async (e) => {
    e.preventDefault();

    if (!validateContact()) return;

    setLoadingContact(true);
    const headers = {
      'Content-Type': 'application/json'
    };

    const payload = {
      websiteLink: websiteLink,
      facebookLink: facebookLink,
      twitterLink: twitterLink,
      about: about
    };

    try {
      const response = await fetch(
        contactUpdate,
        {
          headers,
          method: 'PATCH',
          body: JSON.stringify(payload),
          credentials: 'include'
        }
      );

      if (!response.ok) {
        throw new Error(`This is an HTTP error: The status is ${response.status}`);
      }
      const data = await response.json();
      if (data.status) {
        toast.success(`${data.message}`);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(`Failed to update contact info: ${error.message}`);
    } finally {
      setLoadingContact(false);
    }
  }

  return (
    <div className="account-info">
      <h2>Account Information</h2>
      <div className="profile-photo-section">
        <h3>Profile Photo</h3>
        <div className="profile-photo">
          <img src={profileImage !== null ? profileImage : profilePlaceholder} alt="Profile" />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }}
            id='fileInput'
          />
          <button className="upload-button" onClick={() => document.getElementById('fileInput').click()}>
            <i className="fa fa-camera"></i>
          </button>
        </div>
      </div>
      <div className="profile-info-section">
        <h3>Profile Information</h3>
        <form onSubmit={handleUpdate}>
          <div className="form-group">
            <label>First Name:</label>
            <input
              type="text"
              placeholder="Enter first name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            {errors.firstName && <span className="error">{errors.firstName}</span>}
          </div>
          <div className="form-group">
            <label>Last Name:</label>
            <input
              type="text"
              placeholder="Enter last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            {errors.lastName && <span className="error">{errors.lastName}</span>}
          </div>
          <div className="form-group">
            <label>Home Address:</label>
            <input
              type="text"
              placeholder="Enter home address"
              value={address}
              onChange={(e) => setHomeAddress(e.target.value)}
            />
            {errors.address && <span className="error">{errors.address}</span>}
          </div>
          <div className="form-group">
            <label>Phone Number:</label>
            <input
              type="text"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
          </div>
          <div className="save-profile-section">
            <button
              className="save-profile-button"
              type='submit'
              disabled={loadingProfile}
            >{loadingProfile ? <span className='spinner'></span> : "Save Profile"}
            </button>
          </div>
        </form>
      </div>
      <div className="contact-details-section">
        <h3>Contact Details</h3>
        <p>These details are private and only used to contact you for ticketing or pricing.</p>
        <form onSubmit={handleContactUpdate}>
          <div className="form-group">
            <label>Website:</label>
            <input
              type="text"
              placeholder="Enter website link"
              value={websiteLink}
              onChange={e => setWebsite(e.target.value)}
            />
            {errors.websiteLink && <span className="error">{errors.websiteLink}</span>}
          </div>
          <div className="form-group">
            <label>Facebook:</label>
            <input
              type="text"
              placeholder="Enter facebook link"
              value={facebookLink}
              onChange={e => setFacebook(e.target.value)}
            />
            {errors.facebookLink && <span className="error">{errors.facebookLink}</span>}
          </div>
          <div className="form-group">
            <label>Twitter:</label>
            <input
              type="text"
              placeholder="Enter twitter link"
              value={twitterLink}
              onChange={e => setTwitter(e.target.value)}
            />
            {errors.twitterLink && <span className="error">{errors.twitterLink}</span>}
          </div>
          <div className="form-group">
            <label>About:</label>
            <input
              type="text"
              placeholder="Tell users about yourself"
              value={about}
              onChange={e => setAbout(e.target.value)}
            />
            {errors.about && <span className="error">{errors.about}</span>}
          </div>
          <div className="save-profile-section">
          <button
            className="save-profile-button"
            type='submit'>{ loadingContact ? <span className='spinner'></span> : 'Save Contact'}</button>
        </div>
        </form>
      </div>
      <PaymentInfo bankOptions={bankOptions} />
    </div>
  );
};

export default AccountInfo;
