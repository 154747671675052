import React, { useEffect, useState } from 'react';
import './AccountInfo.css';
import Select from 'react-select';
import customStyles from './SelectStyle';
import { getPaymentInfo, savePaymentInfo, resolveAccount } from '../../constants/routes';
// import { getToken } from '../../utils/auth';
import { toast } from 'react-toastify';

const PaymentInfo = ({ bankOptions }) => {

  const [bankAccName, setBankName] = useState('');
  const [bankAccNumber, setBankNumber] = useState('');
  const [accHolderName, setAccHolderName] = useState('');
  const [bankId, setBankId] = useState(0);
  const [bankCode, setBankCode] = useState('');
  const [hasPaymentInfo, setHasPayment] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [accountVerificationLoading, setAccountVerificationLoading] = useState(false);

  useEffect(() => {
    const fetchPaymentInfo = async () => {
      const headers = {
        'Content-Type': 'application/json'
      };
      try {
        const response = await fetch(
          getPaymentInfo,
          {
            headers,
            method: 'GET',
            credentials: 'include'
          },
          
        );
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        const data = await response.json();
        if (data.hasPaymentInfo) {
          setHasPayment(data.hasPaymentInfo);
          setBankName(data.paymentInfo.bankName);
          setBankNumber(data.paymentInfo.bankAccountNumber);
          setAccHolderName(data.paymentInfo.accountHolderName);
        } else {
          setHasPayment(false);
        }
      } catch (error) {
        toast.error(`Failed to fetch payment info: ${error.message}`);
      } finally {
      }
    };

    fetchPaymentInfo();
  }, []);

  useEffect(() => {
    if (bankAccNumber.length === 10 && !hasPaymentInfo) {
      verifyAccountName(bankAccNumber, bankCode);
    }
  }, [bankAccNumber, bankCode, hasPaymentInfo]);

  const validate = () => {
    const errors = {};
    if (!bankAccName) errors.bankAccName = 'Bank name is required';
    if (!bankAccNumber) errors.bankAccNumber = 'Bank account number is required';
    if (!accHolderName) errors.accHolderName = 'Account holder name is required';
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const verifyAccountName = async (accountNumber, bankCode) => {
    setAccountVerificationLoading(true);
    // const token = getToken();
    const headers = {
      // 'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    try {
      const response = await fetch(
        `${resolveAccount}?accountNumber=${accountNumber}&bankCode=${bankCode}`,
        {
          headers,
          method: 'GET',
          credentials: 'include'
        }
      );
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      const data = await response.json();
      if (data.status) {
        toast.success(data.message);
        setAccHolderName(data.data.account_name);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(`Failed to verify account number: ${error.message}`);
    } finally {
      setAccountVerificationLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setLoading(true);
    // const token = getToken();
    const headers = {
      // 'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    const payload = {
      bankName: bankAccName,
      bankAccountNumber: bankAccNumber,
      accountHolderName: accHolderName,
      bankId: bankId,
      bankCode: bankCode
    };

    try {
      const response = await fetch(
        savePaymentInfo,
        {
          headers,
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        }
      );
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      const data = await response.json();
      if (data.status) {
        toast.success(`${data.message}`);
        setHasPayment(true);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(`Failed to save payment info: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleBankNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    setBankNumber(value);
  };

  return (
    <div className="contact-details-section">
      <h3>Payment Information</h3>
      <p>Input the account details to receive payments.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Bank Name:</label>
          <Select
            options={bankOptions}
            placeholder='Select bank name'
            styles={customStyles}
            isDisabled={hasPaymentInfo}
            value={bankOptions.find(option => option.value === bankAccName)}
            onChange={(e) => {
              setBankId(e.id);
              setBankCode(e.code)
              return setBankName(e.value);
            }} />
          {errors.bankAccName && <span className="error">{errors.bankAccName}</span>}
          {/* <input type="text" placeholder="Enter bank name" /> */}
        </div>
        <div className="form-group">
          <label>Bank Account Number:</label>
          <input
            type="text"
            placeholder="Enter bank account number"
            value={bankAccNumber}
            disabled={hasPaymentInfo || loading}
            onChange={handleBankNumberChange}
            maxLength={10} />
          {errors.bankAccNumber && <span className="error">{errors.bankAccNumber}</span>}
        </div>
        <div className="form-group">
          <label>Account Holder Name:</label>
          <input
            type="text"
            placeholder="Account holder name"
            disabled={true} value={accHolderName}
            onChange={(e) => setAccHolderName(e.target.value)} />
          {errors.accHolderName && <span className="error">{errors.accHolderName}</span>}
        </div>
        {!hasPaymentInfo && <div className="save-profile-section">
          <button type="submit" className="save-profile-button" disabled={loading}>
            {loading ? <span className="spinner"></span> : 'Save Details'}
          </button>
        </div>}
      </form>
    </div>
  );
}

export default PaymentInfo;