// function getCookie(key) {
//     var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
//     console.log(b);
//     return b ? b.pop() : "";
//   }

function deleteCookie() {
    document.cookie = 'jwt=; Max-age=0; path=/;';
}

export const getToken = () => document.cookie.split('; ').find((row) => row.startsWith('jwt='))?.split('=')[1];

// export const setToken = (token) => localStorage.setItem('token', token);

export const removeToken = () => deleteCookie();

export const isAuthenticated = () => !!getToken();