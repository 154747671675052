import React from 'react';
import './Footer.css';
import appleLogo from '../../assets/apple-icon.png';
import googleLogo from '../../assets/google-icon.png';
// import startUpBadge from '../../assets/startup_badge_dark.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section company-info">
          <h4>Company Info</h4>
          <ul>
            {/* <li>About Us</li> */}
            <li><Link to="/terms#section26">Contact Us</Link></li>
            {/* <li>FAQs</li> */}
            <li><Link to='terms'>Terms and Conditions</Link></li>
            <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
          </ul>
        </div>
        
        <div className="footer-section categories">
          <h4>Categories</h4>
          <ul>
            <li>Concerts & Gigs</li>
            <li>Festivals & Lifestyle</li>
            <li>Business & Networking</li>
            <li>Food & Drinks</li>
            <li>Performing Arts</li>
            <li>Sports & Outdoors</li>
            <li>Exhibitions</li>
            <li>Workshops, Conferences & Classes</li>
          </ul>
        </div>
        <div className="footer-section follow-us">
          <h4>Connect With Us</h4>
          <ul>
            <li><Link to='https://www.linkedin.com/company/entertico' target="_blank" rel="noopener noreferrer">LinkedIn</Link></li>
            <li><Link to='https://www.instagram.com/entertico/' target="_blank" rel="noopener noreferrer">Instagram</Link></li>
            <li><Link to='https://www.threads.net/@entertico' target="_blank" rel="noopener noreferrer">Threads</Link></li>
            <li><Link to='https://x.com/entertico_' target="_blank" rel="noopener noreferrer">X (Formerly Twitter)</Link></li>
            {/* <li>Youtube</li> */}
          </ul>
        </div>
        <div>
          <h4>Download The App</h4>
          <div className="app-logos">
            <img src={googleLogo} alt="Google Play" />
            <img src={appleLogo} alt="App Store" />
          </div>
        </div>
        {/* <div className="startup">
          <img src={startUpBadge} alt="startup-badge" />
        </div> */}
      </div>
      <div className="footer-bottom">
        ©2025 Entertico. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
