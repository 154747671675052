import Header from '../components/Header/Header';
import TermsAndConditions from '../components/Privacy/TermsAndConditions';
import Footer from '../components/Footer/Footer';

export default function TermsPage() {
    return(
    <>
      <Header />
      <TermsAndConditions />
      <Footer />
    </>
    );
}