// components/Header.js
import React, { useState, useEffect } from 'react';
import './Header.css';
// import { isAuthenticated } from '../../utils/auth';
import { Link } from 'react-router-dom';
import entertico from '../../assets/entertico.png';

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Add scroll effect to header
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="container">
        <div className="header-left">
          <img src={entertico} alt="Entertico" className="logo" />
        </div>
        <nav className={isMobileMenuOpen ? "nav open" : "nav"}>
          <ul>
            <li><Link to="/" onClick={toggleMobileMenu}>Home</Link></li>
            <li><Link to="/terms#section26" onClick={toggleMobileMenu}>Contact</Link></li>
            <li><Link to="/terms" onClick={toggleMobileMenu}>Terms and Conditions</Link></li>
            <li><Link to="/privacy-policy" onClick={toggleMobileMenu}>Privacy Policy</Link></li>
          </ul>
        </nav>
        <div className="header-right">
          <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
            ☰
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
