import Header from '../components/Header/Header';
import HeroSection from '../components/HeroSection/HeroSection';
// import SearchSection from '../components/SearchSection/SearchSection';
import EventsSection from '../components/Event/EventsSection';
// import CategoriesSection from '../components/Category/CategoriesSection';
import Footer from '../components/Footer/Footer';
import CreateEventSection from '../components/CreateEventSection/CreateEventSection';
import SubscribeSection from '../components/SubscribeSection/SubscribeSection';

export default function HomePage() {
    return(
    <>
      <Header />
      <HeroSection />
      {/* <SearchSection /> */}
      {/* <CategoriesSection /> */}
      <EventsSection />
      <CreateEventSection />
      <SubscribeSection />
      <Footer />
    </>
    );
}