// AccountSettings.js
import React, {useEffect, useState} from 'react';
import './AccountSettings.css';
import AccountInfo from './AccountInfo';
import AccountSidebar from './AccountSidebar';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import AccountPassword from './AccountPassword';
import {getAllBanks} from '../../constants/routes';

const AccountSettings = () => {

  const [allBanks, setBanks] = useState([]);
  const [selectedSection, setSelectedSection] = useState('AccountInfo');

  const renderSection = () => {
    switch (selectedSection) {
      case 'AccountInfo':
        return <AccountInfo allBanks={allBanks} />;
      case 'AccountPassword':
        return <AccountPassword />;
      default:
        return <AccountInfo allBanks={allBanks} />;
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(getAllBanks);
        if(!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        const data = await response.json();
        setBanks(data.data);
      } catch (error) {
      } finally {
      }
    };

    fetchEvents();
  }, []);

  return (
    <div>
        <Header/>
        <div className="account-settings-container">
      <AccountSidebar setSelectedSection={setSelectedSection} selectedSection={selectedSection} />
      {renderSection()}
      {/* <AccountInfo allBanks={allBanks}/> */}
    </div>
    <Footer/>
    </div>
  );
};

export default AccountSettings;
